.page {
  width: 100vw;
  min-height: calc(100vh - env(safe-area-inset-top));
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  margin-top: env(safe-area-inset-top);
}

.staging {
  width: 100vw;
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.top-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.875rem;
  align-self: stretch;
}

.title-and-subnav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.875rem;
  align-self: stretch;
}

.title {
  display: flex;
  height: 6.75rem;
  align-items: flex-end;
  align-self: stretch;
}

.entries {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
}

.entry {
  display: flex;
  padding: 0.5rem 0rem 0.5rem 0rem;
  align-items: flex-start;
  gap: 0.375rem;
  align-self: stretch;
  border-bottom: 1px solid var(--primitives-color-light);
  color: var(--primitives-color-light);
}
.entry.alert,
.entry.alert .entry-player .entry-player-name {
  color: var(--primitives-color-navigation-alert);
}
.entry-dark {
  border-bottom: 1px solid var(--primitives-color-dark);
  color: var(--primitives-color-dark);
}

.entry-number {
  display: flex;
  width: 1.25rem;
  align-items: flex-start;
  gap: 0.375rem;
  flex-shrink: 0;
}

.entry-player {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  min-width: 0;
}

.entry-player-name {
  padding-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.entry-dash {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.entry-tie {
  display: flex;
  padding: 0rem 0.75rem;
  justify-content: center;
  align-items: flex-start;
}

.entry-standing {
  display: flex;
  align-items: flex-end;
  gap: 0.75rem;
}

.entry-player input[type="text"] {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  display: flex;
  height: 1.25rem;
  max-width: 85%;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1 0 0;
  color: var(--primitives-color-light);
}

.entry-player input[type="text"]::placeholder {
  color: var(--primitives-color-navigation-next);
}

.entry-player input:focus {
  outline: none;
}

.subnavigation {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}
.subnavigation.single-right {
  justify-content: right;
}
.subnavigation.double {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
}
.subnav-button {
  display: flex;
}

.bottom-navigation {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.bottom-navigation.single {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1 0 0;
  text-align: right;
}
.bottom-navigation.double {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1 0 0;
}

.bottom-navigation h1 {
  text-align: right;
}

/* Dialogs */
.dialog-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primitives-color-background-BG-dialog);
  backdrop-filter: blur(5px);
  z-index: 2;
}

.dialog {
  display: flex;
  flex-direction: column;
  gap: 1px;
  width: 60%;
  border-radius: 0.75rem;
  box-shadow: 0 0.1rem 0.3rem var(--primitives-color-dropshadow);
  background-color: var(--primitives-color-dropshadow);
}

.dialog-top {
  padding: 1.75rem;
  background-color: var(--primitives-color-light);
  border-radius: 0.75rem 0.75rem 0 0;
  text-align: center;
}

.dialog-body {
  margin-top: 0.75rem;
}

.dialog-button-column {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.dialog-button-pair {
  display: flex;
  flex-direction: row;
  align-content: space-evenly;
  gap: 1px;
}

.dialog-button {
  flex: 1 1 0px;
  text-align: center;
  padding: 1rem 1rem 0.85rem 1rem;
  background-color: var(--primitives-color-light);
}

.dialog-button-last {
  border-radius: 0 0 0.75rem 0.75rem;
}
.dialog-button-left {
  border-radius: 0 0 0 0.75rem;
}
.dialog-button-right {
  border-radius: 0 0 0.75rem 0;
}

/* Typography */
h1 {
  font-family: var(--header-font-family);
  font-weight: var(--header-font-weight);
  color: var(--primitives-color-light);
  font-size: var(--header-font-size);
  letter-spacing: var(--header-letter-spacing);
  line-height: var(--header-line-height);
  font-style: var(--header-font-style);
}

.preheader {
  font-family: var(--pre-header-font-family);
  font-weight: var(--pre-header-font-weight);
  font-size: var(--pre-header-font-size);
  letter-spacing: var(--pre-header-letter-spacing);
  line-height: var(--pre-header-line-height);
  font-style: var(--pre-header-font-style);
}
.logo {
  font-family: var(--hp360-font-family);
  font-weight: var(--hp360-font-weight);
  font-size: var(--hp360-font-size);
  letter-spacing: var(--hp360-letter-spacing);
  line-height: var(--hp360-line-height);
  font-style: var(--hp360-font-style);
}
.preheader.hp360 {
  position: relative;
  top: 0.2rem;
}

.subnav-button,
.dialog-button,
.dialog-h1 {
  font-family: var(--sub-nav-font-family);
  font-weight: var(--sub-nav-font-weight);
  font-size: var(--sub-nav-font-size);
  letter-spacing: var(--sub-nav-letter-spacing);
  line-height: var(--sub-nav-line-height);
  font-style: var(--sub-nav-font-style);
}

.entry,
.dialog-body {
  font-family: var(--name-font-family);
  font-weight: var(--name-font-weight);
  font-size: var(--name-font-size);
  letter-spacing: var(--name-letter-spacing);
  line-height: var(--name-line-height);
  font-style: var(--name-font-style);
}
.entry-number {
  font-family: var(--entry-number-font-family);
  font-weight: var(--entry-number-font-weight);
  font-size: var(--entry-number-font-size);
  letter-spacing: var(--entry-number-letter-spacing);
  line-height: var(--entry-number-line-height);
  font-style: var(--entry-number-font-style);
}

.entry-standing {
  font-family: var(--standing-font-family);
  font-weight: var(--standing-font-weight);
  font-size: var(--standing-font-size);
  letter-spacing: var(--standing-letter-spacing);
  line-height: var(--standing-line-height);
  font-style: var(--standing-font-style);
}

.entry-player input[type="text"] {
  font-family: inherit;
  font-size: inherit;
}

/* Higlight the winners */
.entry-player.next,
.entry-tie.standings {
  font-weight: var(--sub-nav-font-weight);
}
.entries.final-standings .entry:first-child {
  font-weight: 600;
}

/* Colors */
.dark {
  color: var(--primitives-color-dark);
}
.light {
  color: var(--primitives-color-light);
}
.navigation {
  color: var(--primitives-color-navigation-nav);
}
.greyed-out {
  color: var(--primitives-color-navigation-greyed-out);
}
.next {
  color: var(--primitives-color-navigation-next);
}
.alert {
  color: var(--primitives-color-navigation-alert);
}
.hp360 {
  color: var(--primitives-color-states-hp360);
}
.players {
  color: var(--primitives-color-states-players);
}
.pairings {
  color: var(--primitives-color-states-pairings);
}
.standings {
  color: var(--primitives-color-states-standings);
}
.in-progress {
  color: var(--primitives-color-states-inprogress);
}
.edit {
  color: var(--primitives-color-states-edit);
}

.ios-appstore-button {
  max-width: 120pt;
}

/* Colors Backgrounds */

html:has(.page.bg-hp360) {
  background: var(--primitives-color-dark);
}
html:has(.page.bg-players) {
  background: var(--primitives-color-background-BG-add-players);
}
html:has(.page.bg-pairings) {
  background: var(--primitives-color-background-BG-pairings);
}
html:has(.page.bg-in-progress) {
  background: var(--primitives-color-background-BG-inprogress);
}
html:has(.page.bg-standings) {
  background: var(--primitives-color-background-BG-standings);
}
html:has(.page.bg-final-standings) {
  background-color: #735600;
  background-image: var(--primitives-color-background-BG-final-standings);
}
html:has(.page.bg-edit) {
  background: var(--primitives-color-background-BG-edit);
}
.bg-staging {
  background: #ddd;
}

/* Animations and Transitions */

html {
  transition: background-color 0.2s;
}

.link {
  transition: color 0.3s;
}

.entries.final-standings .entry:first-child {
  --color-one: var(--primitives-color-states-standings);
  --color-two: var(--primitives-color-light);
  background: linear-gradient(45deg, var(--color-one) 45%, var(--color-two) 55%, var(--color-one) 55%) 0 0 / 400% 100%;
  color: transparent;
  background-clip: text;
  animation: text-gradient 6s infinite linear;
  animation-direction: reverse;
}

/* .impatient {
  --color-one: var(--primitives-color-states-standings);
  --color-two: var(--primitives-color-light);
  background: linear-gradient(90deg, var(--color-two) 0%, var(--color-one) 2%) 0
    0 / 200px 500px;
  color: transparent;
  background-clip: text;
  animation: text-gradient 6s infinite linear;
  animation-direction: reverse;
}

@keyframes text-gradient {
  to {
    background-position: 1000px 0;
  }
} */

/* Reset */
/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}

/* Design fixes */
html {
  font-size: 16px;
}
a {
  text-decoration: none;
}
.link:hover {
  cursor: pointer;
}

.icon {
  position: relative;
  top: 0px;
}

h1 {
  padding-top: 0.5rem;
}

input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
